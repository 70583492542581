<template>
  <div class="asset-level">
    <h3 class="headline-medium">{{$t('asset_level_modal.modal_title')}}</h3>
    <template v-for="item of content">
      <div class="border-b border-border py-s16 asset-level-item" :key="item.title">
        <h4 class="subheadline-small">{{item.title}}</h4>
        <p class="body-text-large text-text-inactive mt-s8">{{item.description}}</p>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: 'AssetLevel',
  computed: {
    content(){
      return [
        {
          title: this.$t('asset_level_modal.title.holdings'),
          description: this.$t('asset_level_modal.description.holdings')
        },
        {
          title: this.$t('asset_level_modal.title.average_price'),
          description: this.$t('asset_level_modal.description.average_price')
        },
        {
          title: this.$t('asset_level_modal.title.net_cost'),
          description: this.$t('asset_level_modal.description.net_cost')
        },
        {
          title: this.$t('asset_level_modal.title.profit_loss'),
          description: this.$t('asset_level_modal.description.profit_loss')
        },
      ];
    }
  }
};
</script>
<style scoped>
  .asset-level-button {
    outline: 0;
  }

  .asset-level-item:first-child{
    padding-top: 0;
  }

  .asset-level-item:last-child{
    border: 0;
    padding-bottom: 0;
  }
</style>
